import React, {useState} from "react"
import { StaticImage, GatsbyImage, getImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Banner from "../components/banner"
import { useStaticQuery, graphql, withPrefix, Link, navigate} from "gatsby"
import Seo from "../components/seo"
const banner_title = ['Answering your Query', 3000, 'To know your business', 2000, 'To work for you', 1000]; 


//document.querySelector("form").addEventListener("submit", handleSubmit);
/*
const handleSubmit = (e) => {
  e.preventDefault()
  let myForm = document.getElementById('pizzaOrder');
  let formData = new FormData(myForm)
  console.log('formdata', formData);
  fetch('/', {
    method: 'POST',
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString()
  }).then(() => console.log('Form successfully submitted')).catch((error) =>
    alert(error))
}
*/

const Contact_us = () => {


    /*const [formState,setFormState] = useState({

        name : "",
        email  :"",
      
    })

    
  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }


    const handleChange = (e) => {

        


            const services = e.target.name;
  if (services.includes('[]')) {
    setFormState(state => {
      let array = state[services] || []
      array = e.target.checked === true ? [...array, e.target.value] : array.filter(element => element !== e.target.value)
      return {
        ...formState,
       [services]: array
      }
    })
  }
  
  else {

    setFormState({

      ...formState,
      [e.target.name] : e.target.value,
      

  })

  }

  

    }
*/
    const handleSubmit = (e) => {
      let myForm = document.getElementById('contact-form');
  let formData = new FormData(myForm)

        fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams( formData).toString()
      })
        .then(() =>{
          navigate("/thank-you/")
         /* document.getElementById("response_msg_contact").innerHTML = 
          `<div class="alert alert-success" role="alert">
          Thank You for contacting us. We will get back in touch with you soon!
          </div>`;
         // alert("Success!");
          document.getElementById("contact-form").reset();
          window.scrollTo(0, 200);
*/
        })
        .catch(error => alert(error));

      e.preventDefault();

        
    }
    return (

        <Layout>
<Seo title="Get free consultation for any kind of Query. We are here to fulfill all your demands " />
        <Banner className = "service" title = {banner_title}  sticky_first = "We are always" sticky_second  = "interested in"  />

        <section className="intro_part contact_us">
          <div className="container">
            <h3>Get in touch  </h3>
        <p>Have a problem? Call, email, chat or let’s have a cup of coffee together! </p></div></section>

      <section className = "contact_form">

        <div className = "container">

          <div className = "main_grid">

              <div className = "left_info">

              <h1>We can do better together. </h1>

                <h3>call us</h3>
                <a href ="tel:2162796748">(216) 279-6748</a>
                <h3>email us</h3>
                <h4>sales</h4>
                <a href ="">sales@estorecompany.com</a>
                <h4>support</h4>
                <a href ="">support@estorecompany.com</a>

              </div>
              <div className = "right_info">
          <div id = "response_msg_contact"></div>
              <form onSubmit = {handleSubmit} id ="contact-form" name="contact"  method="post" data-netlify="true" data-netlify-honeypot="bot-field">

              <div className = "fields"><input type="hidden" name="form-name" value="contact" /></div>
              <div className = "fields"> <input type="text" name="name"  placeholder = "Name" required/></div>
              <div className = "fields"> <input type="tel" name="telephone" placeholder = "Telephone" required  /></div>
              <div className = "fields"><input type="email" name="email"  placeholder = "Email"  required/></div>
              <div className = "fields">
                <label>products / services</label>
              <div className="services">

                <div className="ecommerce">
              
                <div><input type="checkbox" id ="ecommerce" name="services[]" value = "Graphic Design" /><label htmlFor = "ecommerce" >GRAPHICS DESIGN</label> </div>
                <div> <input type="checkbox" id = "digital" name="services[]" value = "Web Design" /><label htmlFor ="digital" >WEB DESIGN</label> </div>
                <div> <input type="checkbox" id ="market" name="services[]" value = "Digital Marketing" /> <label htmlFor ="market" >DIGITAL MARKETING</label></div>
                <div> <input type="checkbox" id ="custom" name="services[]" value = "Virtual Assistance" /><label htmlFor ="custom">VIRTUAl ASSISTANCE</label> </div>
</div>
                <div className="general">

                <div> <input type="checkbox" id ="web" name="services[]" value = "Web Development" />  <label htmlFor ="web">WEB DEVELOPMENT</label></div>
                <div> <input type="checkbox" id ="design" name="services[]" value = "Ecommerce Development"/>  <label htmlFor ="design">ECOMMERCE DEVELOPMENT</label></div>
                <div> <input type="checkbox" id ="logo" name="services[]" value = "CMS Development" /><label htmlFor ="logo">CMS DEVELOPMENT</label></div>
                <div> <input type="checkbox" id = "graphics" name="services[]" value = "Other"/><label htmlFor = "graphics" >OTHER</label> </div>





                </div>

              </div>
              </div>


              <div className = "fields">

              <textarea placeholder = "Comments" name = "brief"></textarea>
              </div>

            <button type = "submit">SUBMIT</button>
              </form>

              </div>
          </div>


        </div>

      </section>



<section className = "section_batches">

<div className="container">

<div className = "batches">

  <ul>
    <li> <StaticImage
      src="../images/badge1.png"
      quality = "90"
       alt="A Gatsby astronaut"
       placeholder="tracedSVG"
       /></li>
    <li><StaticImage
      src="../images/badge2.png"
      quality = "90"
       alt="A Gatsby astronaut"
       placeholder="tracedSVG"
       /></li>
    <li><StaticImage
      src="../images/badge3.png"
      quality = "90"
       alt="A Gatsby astronaut"
       placeholder="tracedSVG"
       /></li>
    <li><StaticImage
      src="../images/badge4.png"
      quality = "90"
       alt="A Gatsby astronaut"
       placeholder="tracedSVG"
       /></li>
    <li><StaticImage
      src="../images/badge5.png"
      quality = "90"
       alt="A Gatsby astronaut"
       placeholder="tracedSVG"
       /></li>
    <li><StaticImage
      src="../images/badge6.png"
      quality = "90"
       alt="A Gatsby astronaut" 
       placeholder="tracedSVG"
       /></li>
    <li><StaticImage
      src="../images/badge7.png"
      quality = "90"
       alt="A Gatsby astronaut"
       placeholder="tracedSVG"
       /></li>
      </ul>
</div>

</div>  
</section>



<section className = "locations">

      <div className = "container">

          <ul>

            <li>

            <StaticImage
      src="../images/usa.png"
      quality = "90"
       alt="USA" 
       placeholder="tracedSVG"
       />
            </li>

            <li>

<StaticImage
src="../images/pak.png"
quality = "90"
alt="PAKISTAN"
placeholder="tracedSVG"
/>
</li>


<li>

<StaticImage
src="../images/uae.png"
quality = "90"
alt="UAE" 
placeholder="tracedSVG"
/>
</li>


<li>

<StaticImage
src="../images/ire.png"
quality = "90"
alt="IRE" 
placeholder="tracedSVG"
/>
</li>



          </ul>



      </div>



</section>




</Layout>


    )


}



export default Contact_us